




























/* eslint-disable */
import { Component, Vue, Prop } from 'vue-property-decorator';
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
// import MILIDConfettis from './MILIDConfettis.vue';

import { ConfettiElement } from './confetti/ConfettiElement';

@Component({
  components: { 
    LottieAnimation,
    // MILIDConfettis
  }
})
export default class CompletionButton extends Vue {

    @Prop() completed!:boolean;

    lottieController:any = null;
    lottieOptions:any = null;

    isPlaying = false;
    displayParty = false;

    confettiElement!: ConfettiElement | null;

    get lottiePath(){
      return "lottie/validation_lecon.json";
    }

    setAnimController(controller){
      this.lottieController = controller;

      // if already complete go to last frame
      if(this.completed){
        const isFrame = true;
        const duration = this.lottieController.duration;
        this.lottieController.goToAndStop(this.lottieController.totalFrames - 1, isFrame);
      }else{
        this.lottieController.addEventListener("complete", this.animationCompleteHandler);
      }
    }
  
    mounted(){
      // console.log("button is completed ?", this.completed);
    }
    
    beforeDestroy(){
      if(this.lottieController){
        this.lottieController.removeEventListener("complete", this.animationCompleteHandler);
      }

      if(this.confettiElement){
        this.confettiElement.unmount();
      }
    }

    animationCompleteHandler(){
      // console.log("animation is complete");
      this.isPlaying = false;
      this.$emit('complete');
    }

    clickHandler(){
      if(this.completed || !this.lottieController || this.isPlaying) return;

      this.lottieController.goToAndPlay(1, true);
      this.isPlaying = true;
      this.displayParty = true;      

      this.confettiElement = new ConfettiElement();
      this.confettiElement.mount();
      // console.log("on click");
    }
}
