


































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class MILIDWave extends Vue {
  @Prop({ default : 'top'}) private name!: string;
  @Prop({ default : '1'}) private theme!: string;
  @Prop({ default : '1000'}) private width!: string;
  @Prop({ default : '235'}) private height!: string;
  @Prop({ default : {}}) private config!: any;


  get viewBox() {
    return "0 0 "+this.width+" "+this.height;
  }

  get colorPrimary() {
    return this.config.themes[this.theme].primary;
  }

  get colorSecondary() {
    return this.config.themes[this.theme].secondary;
  }

  get colorTertiary() {
    return this.config.themes[this.theme].tertiary;
  }

}
