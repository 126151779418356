








































































/* eslint-disable */

import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Toggle extends Vue {
  @Prop() readonly disabled!:boolean;

}
