














































































































































/* eslint-disable */
import { Component, Vue, Prop } from "vue-property-decorator";
import MILIDIcons from "./MILIDIcons.vue";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
import CompletionButton from "./CompletionButton.vue";

import { $config, $module, $metric } from "@/services";
import { MILID } from "../models";
import { formatTime } from "../helpers/milidHelpers";

@Component({
  components: {
    MILIDIcons,
    LottieAnimation,
    CompletionButton,
  },
})
export default class LessonPodcast extends Vue {
  @Prop() readonly moduleId!: string;
  @Prop() readonly lessonId!: string;

  lottieController: any = null;
  lottieOptions: any = null;

  elapsed = "";
  duration: any = "";

  isPlaying = false;

  isReadyToPlay = false;
  isLoading = true;

  get lesson() {
    return $module.getLessonForModuleAndLessonId(this.moduleId, this.lessonId);
  }

  get theme() {
    return $module.getModuleWithId(this.moduleId).theme;
  }

  get lottiePath() {
    return `lottie/podcast-theme-${this.moduleId}.json`;
  }

  get title() {
    return this.lesson.title;
  }

  get completed() {
    return this.state == "done";
  }

  get state() {
    const metric = $metric.progressionState[this.lesson.id] || {};
    return metric.state || "";
  }

  get mediaUrl() {
    return this.lesson.media;
  }

  get done() {
    return $metric.progressionState[this.lessonId];
  }

  get elapsedStr() {
    return formatTime(this.elapsed);
  }

  get durationStr() {
    return formatTime(this.duration);
  }

  setAnimController(controller) {
    this.lottieController = controller;
  }

  async beforeMount() {}

  get audioPlayer() {
    return this.$refs.audioPlayer as HTMLAudioElement;
  }

  mounted() {
    this.audioPlayer.addEventListener("ended", this.onTrackEnded);
    this.audioPlayer.addEventListener("timeupdate", this.onTimeUpdate);
    this.audioPlayer.addEventListener("loadedmetadata", this.onMetaLoaded);
    this.audioPlayer.addEventListener("canplay", this.onCanPlay);
  }

  beforeDestroy() {
    this.audioPlayer.removeEventListener("ended", this.onTrackEnded);
    this.audioPlayer.removeEventListener("timeupdate", this.onTimeUpdate);
    this.audioPlayer.removeEventListener("loadedmetadata", this.onMetaLoaded);
    this.audioPlayer.removeEventListener("canplay", this.onCanPlay);
  }

  pause() {
    if (!this.isPlaying) return;

    this.audioPlayer.pause();
    this.isPlaying = false;

    if (this.lottieController) {
      this.lottieController.pause();
    }
  }

  play() {
    if (this.isPlaying) return;

    this.audioPlayer.play().catch(err => console.log('---DBG',err));
    this.isPlaying = true;
    this.isReadyToPlay = true;
    if (this.lottieController) {
      this.lottieController.play();
    }
  }

  seekForwards() {
    this.audioPlayer.currentTime = Math.min(
      this.audioPlayer.currentTime + 10,
      this.audioPlayer.duration
    );
  }

  seekBackwards() {
    this.audioPlayer.currentTime = Math.max(
      this.audioPlayer.currentTime - 10,
      0
    );
  }

  onToggle() {
    this.isPlaying ? this.pause() : this.play();
  }

  onCompletionHandler() {
    const params = {
      lesson: this.lessonId,
      module: this.moduleId,
      state: MILID.LessonState.DONE,
    };
    $metric.event(params);
  }

  onCanPlay() {
    // ISSUE on iOS
    // https://stackoverflow.com/questions/50051639/javascript-html5-video-event-canplay-not-firing-on-safari
    this.isLoading = false;
  }

  onMetaLoaded() {
    this.isLoading = false;
    this.duration = Math.floor(this.audioPlayer.duration);
    this.elapsed = "0";
  }

  onTrackEnded() {
    this.isPlaying = false;
    this.lottieController.pause();
  }

  onTimeUpdate() {
    this.elapsed = `${Math.floor(this.audioPlayer.currentTime)}`;
  }

}
