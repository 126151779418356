




































































































































































































































































import axios from 'axios';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { config } from 'vue/types/umd';
import { MILID } from '../models';
import { $config } from '../services';

@Component
export default class MILIDIcons extends Vue {
  @Prop({ default : 'home'}) private name!: string;
  @Prop({ default : '1'}) private theme!: string;
  @Prop({ default : '18'}) private width!: string;
  @Prop({ default : '18'}) private height!: string;
  @Prop() private color!: string;

  content = "";

  async created() {
    // const res = await axios.get(this.config.baseUrl + `assets/icons/${this.name}.svg`, {responseType: 'text'});
    // this.content = res.data as string;
  }

  get config() {
    return $config.store.config;
  }

  get viewBox() {
    return "0 0 100 100";
  }

  get colorPrimary() {
    if(this.color) {
      return this.color;
    }
    return this.config.themes[this.theme].primary;
  }

  get colorSecondary() {
    if(this.color) {
      return this.color;
    }
    return this.config.themes[this.theme].secondary;
  }

  get colorTertiary() {
    if(this.color) {
      return this.color;
    }
    return this.config.themes[this.theme].tertiary;
  }

}
