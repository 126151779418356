


















































































































/* eslint-disable */
import { Component, Vue, Prop } from 'vue-property-decorator';

import { $module } from '@/services/module-service';
import { $config } from '@/services/config-service';

import { QuestionSet } from '../helpers/QuizModel';

@Component({
  components: { },
})
export default class Quiz extends Vue {
  @Prop() readonly moduleId!:string;
  @Prop() readonly lessonId!:string;

  quizContent = "";
  questionSets: any = []

  validationText = "";

  allQuestionsHaveAtLeastOneAnswer = false;

  showRetryBtn = false;

  buttonKey = 0; // allows remount of click once buttons (resets once directive)

  beforeMount(){
    this.quizContent = this.lesson.quiz;
  }
  
  mounted(){
    this.setupQuiz();
  }

  beforeDestroy(){
    this.cleanup();
  }
  
  get module() {
    return $module.getModuleWithId(this.moduleId);    
  }

  get lesson(){
    return $module.getLessonForModuleAndLessonId(this.moduleId, this.lessonId);
  }
  
  get cssVars(){
      return {
        '--lesson-color': $config.store.config.themes[this.module.theme].primary,
      };
  }

  validate(){
    let correctAnswersCount = 0;

    for(const questionSet of this.questionSets){
      console.log(questionSet.id, questionSet.hasCorrectAnswer());
      if(questionSet.hasCorrectAnswer()){
        correctAnswersCount++;
      }
      questionSet.validate();
      questionSet.setDoneAppearance();
    }

    const plural = correctAnswersCount > 1 ? 's' : '';

    const questionCount = this.questionSets.length;

    if(correctAnswersCount == questionCount){
      // no errors
      this.validationText = "Bravo, tu as répondu correctement au Quizz!";
    }else{
      // there were errors
      this.validationText = `Tu as trouvé ${correctAnswersCount} réponse${plural} sur ${questionCount}.`
      // offer retry option
      this.showRetryBtn = true;
    }
  }

  reStart(){
    this.showRetryBtn = false;
    this.validationText = "";
    this.buttonKey++;
    this.allQuestionsHaveAtLeastOneAnswer = false;
    for(const questionSet of this.questionSets){
      questionSet.reset();
    }
  }

  computeStatus(){
    for(const questionSet of this.questionSets){
      if(!questionSet.hasAtLeastOneAnswer()){
        return false;
      }
    }
    return true;
  }

  quizAnswerHandler(e){
    // find questionSet containing button and deactivate all buttons
    const id = e.target.id;
    this.questionSets.find(questionSet => questionSet.containsId(id)).deActivateAllButtons();
    
    // activate target
    e.target.classList.toggle('active');
    this.allQuestionsHaveAtLeastOneAnswer = this.computeStatus();
    console.log("status", this.allQuestionsHaveAtLeastOneAnswer);
  }

  setupQuiz(){
      const rawRoot = this.$refs['raw_root'] as HTMLElement;
      const questionDivs = rawRoot.querySelectorAll('.question');

      let questionSetId = 0;
      let idCounter = 0;

      // for each collection of answers found in a div.question
      // create a question set;
      questionDivs.forEach(questionDiv => {
        const questionSet = new QuestionSet(questionSetId.toString());
       
        questionDiv.querySelectorAll('.quiz-btn').forEach((quizBtn ) => {
          const id = `qust_btn_id_${idCounter++}`;
          quizBtn.id = id;
          (quizBtn as HTMLElement).dataset.questionId = questionSetId.toString();
          quizBtn.addEventListener('click', this.quizAnswerHandler)
          questionSet.addAnswer(id, quizBtn);
        });

        this.questionSets.push(questionSet);
        questionSetId += 1;
      });
  }

  cleanup(){
    const rawRoot = this.$refs['raw_root'] as HTMLElement;
    const questionDivs = rawRoot.querySelectorAll('.question');

    questionDivs.forEach(questionDiv => {
      questionDiv.querySelectorAll('.quiz-btn').forEach(quizBtn => {
        quizBtn.removeEventListener('click', this.quizAnswerHandler)
      });
    });
  }

}
