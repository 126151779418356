
































































































import { Component, Vue } from 'vue-property-decorator';

import MILIDIcons from '../components/MILIDIcons.vue';
import ParametersPage from '../components/ParametersPage.vue';
import  cfg from '../../package.json';

@Component({
  components: {
    MILIDIcons,
    ParametersPage,
  },
})
export default class About extends Vue { 

  version = cfg.version;

  showParameters = false;
  
  onBack() {
    this.$router.go(-1);
    //this.$router.push({ path: `/module`});
  }

}
