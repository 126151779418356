


































































































/* eslint-disable */
import MILIDIcons from './MILIDIcons.vue';
import Toggle from './Toggle.vue';

import { Component, Prop, Vue } from 'vue-property-decorator';

import { $user } from '../services/user-service';
import  cfg from '../../package.json';

@Component({
  components: { MILIDIcons, Toggle },
})
export default class ParametersPage extends Vue {

  @Prop() readonly open!: boolean; 
  version = cfg.version;

  showAboutButton = true;

  mounted(){
    this.showAboutButton = this.$route.name != "About";
  }

  close(){
    this.$emit('closerequest', null);
  }

  get uid(){
    return $user.getDeviceID();
  }
}

