




































































import { Component, Vue } from 'vue-property-decorator';
import { Route } from 'vue-router';
import { $config, $metric, $module, $user } from '../services';

import ModuleStatus from '../components/ModuleStatus.vue';
import MILIDWave from '../components/MILIDWave.vue';
import MILIDIcons from '../components/MILIDIcons.vue';
import ParametersPage from '../components/ParametersPage.vue';
import { MILID } from '../models';


@Component({
  components: { 
    ModuleStatus, 
    MILIDWave, 
    MILIDIcons,
    ParametersPage, 
  }
})
export default class Home extends Vue {
  private lastScrollTop = 0;

  scrollDirection = 0;

  showParameters = false;

  get modules() {
    return $module.modules;    
  }

  get config(){
    return $config.store.config;
  }

  get pseudo() {
    return $user.user.name;
  }

  getState(lesson) {
    const metric = $metric.progressionState[lesson.id] || {};
    return metric.state || '';
  }

  getType(lesson) {
    return this.config.icons[lesson.type];
  }

  themeTertiary(theme) {
    return this.config.themes[theme].tertiary;
  }

  beforeRouteEnter(to: Route, from: Route, next: any) {
    const load = [$config.get(),$module.getAll(),$metric.get()];
    Promise.all(load).then(next);
  }

  mounted(){
    window.addEventListener("scroll", () => { 
      const st = window.pageYOffset || document.documentElement.scrollTop;
      //
      // downscroll code
      if (st > this.lastScrollTop){
        this.scrollDirection = 1;
      } 
      //
      // upscroll code
      else {          
        this.scrollDirection = -1;
      }

      //
      // For Mobile or negative scrolling
      this.lastScrollTop = st <= 0 ? 0 : st; 

    }, false); 
       
    const params = {
        lesson: 'home',
        module: 'home',
        state: MILID.LessonState.DONE
    };
    $metric.event(params);
  }

  routerLink(module,lesson) {
    this.$router.push({path:'/module/' + module + '/lesson/' + lesson});
  }

  onIndex($event, module) {
    $event.stopPropagation();
    const element = document.getElementById('m'+module);
    if(!element) {
      return;
    }
    element.scrollIntoView({ behavior: 'smooth' });
  }

  onTop($event) {
    this.onIndex($event,1);
  }
}
