
































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { $config } from './services';

@Component
export default class App extends Vue {
  displayUseSafari = false;  
  displayIosInstall = false;
  registration: any = {};
  updateExists = false;


  i18n(key) {
    return $config.i18n(key);
  }

  mounted() {
    //
    // delegate browser install Prompt
    window.addEventListener('installprompt', () => {
      console.log('iOS browser prompt');
      if(!$config.isSafari()) {
        this.displayUseSafari =  true;  
        return;
      }
      this.displayIosInstall =  true;
      setTimeout(()=>{
        this.displayUseSafari =  false;  
        this.displayIosInstall =  false;
      },10000);
    });

    //
    // update app
    window.addEventListener('swUpdated', this.onUpdateAvailable, { once: true })

  }  

  onUpdateAvailable(event){
      this.registration = event.detail
      this.updateExists = true;
      this.onRefreshApp();
  }

  onClose(){
    this.displayIosInstall = false;
  }

  onRefreshApp() {
    this.updateExists = false
    // Make sure we only send a 'skip waiting' message if the SW is waiting
    if (!this.registration || !this.registration.waiting) return
    // Send message to SW to skip the waiting and activate the new SW
    this.registration.waiting.postMessage({ type: 'SKIP_WAITING' });
    //
    //refresh content from registerServiceWorker.ts
    //setTimeout(()=> window.location.reload(true),3000);
  }  
}
