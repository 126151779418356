



import { Component, Prop, Vue } from 'vue-property-decorator';
import Hammer from 'hammerjs';
import debounce from 'lodash.debounce';

@Component({
  components: {  }
})
export default class ContentSwipe extends Vue {
  @Prop() lessons!: any[];
  @Prop({ default: 0 }) initial!: number;

  isInfiniteLoop = false; // Whether to loop back to start of item array when reaching the end
  prefersReducedMotion = false;
  currentIndex = 0;
  upcomingIndex = 0;
  translateX = 0;
  maxTranslateX = 0;
  transformStyle = "translateX(0)";
  transitionClass = "transition-initial";
  isTransitioning = false;
  leftEdgeScale = 0;
  rightEdgeScale = 0;

  // Returns array of objects with id & key for each item
  // For the v-for loop, each slide needs a stable and unique key
  get infoItems() {
    let arr = [...this.lessons];
    // If there are only 2 items, double array to always have odd number in renderedItems
    if (arr.length === 2) {
      arr = [...arr, ...arr];
    }
    return arr.map((lesson, index) => ({
      id:(lesson.id+''+index),
      key: `${lesson.id}-${index}`,
      value: lesson
    }));
  }

  // Return array of objects for the 3 items to be rendered in the DOM at the moment
  // Includes the previous, current, and next slides
  get renderedItems() {
    const { currentIndex: i, infoItems } = this;

    // console.log('---- DBG: infoItems',this.infoItems);
    if (infoItems.length === 1) {
      return [this.lessons[0],this.lessons[0],this.lessons[0]];
    }

    const lastIndex = this.infoItems.length - 1;
    const prevIndex = i === 0 ? lastIndex : i - 1;
    const nextIndex = i === lastIndex ? 0 : i + 1;

    // console.log('---- DBG: currentIndex',this.currentIndex);
    // console.log('---- DBG: index',prevIndex,i,nextIndex, this.infoItems);
    // console.log('---- DBG: lessons',prevIndex,i,nextIndex, this.lessons);
    return [this.infoItems[prevIndex].value, this.infoItems[i].value, this.infoItems[nextIndex].value];
  }

  get isNextAvailable() {
    const { lessons, currentIndex, isInfiniteLoop } = this;
    return (
      currentIndex < lessons.length - 1 ||
      (isInfiniteLoop && lessons.length !== 1)
    );
  }

  get isPreviousAvailable() {
    const { lessons, currentIndex, isInfiniteLoop } = this;
    return currentIndex > 0 || (isInfiniteLoop && lessons.length !== 1);
  }

  mounted() {
    // use prop to initiate the currentIndex;
    this.currentIndex = this.initial;

    // Set up Hammer element & event listeners to respond to swiping gestures
    const touchContainer = document.getElementById("touch-container");
    const hammer = new Hammer.Manager(touchContainer, {
      recognizers: [
        [Hammer.Pan, { direction: Hammer.DIRECTION_HORIZONTAL }],
        [Hammer.Swipe, { direction: Hammer.DIRECTION_HORIZONTAL }]
      ]
    });
    hammer.on("pan swipe", this.handleTouchEvents);

    // Set up event listeners for when items are transitioning across the screen
    const itemsContainer = document.getElementById("rendered-items-flexbox");
    if(itemsContainer) {
      itemsContainer.addEventListener("transitionstart", (e) => {
        if (e.target === itemsContainer) {
          this.isTransitioning = true;
        }
      });
      itemsContainer.addEventListener("transitionend", (e) => {
        if (e.target === itemsContainer) {
          this.updateCurrentItem();
        }
      });
    }

    // For users who prefer reduced motion, can't rely on transition to change items
    this.prefersReducedMotion = window.matchMedia(
      "(prefers-reduced-motion: reduce)"
    ).matches;

    this.firstPaint();
  }

  firstPaint(){
    this.upcomingIndex = this.currentIndex;
    this.updateCurrentItem();
  }

  handleTouchEvents(e) {
    const {
      isTransitioning,
      translateX,
      leftEdgeScale,
      rightEdgeScale,
      isPreviousAvailable,
      isNextAvailable
    } = this;
    const { deltaX, deltaY, isFinal } = e;
    // console.log('--- DBG hammer gesture, prev,next',isPreviousAvailable, isNextAvailable)
    // console.log('--- DBG hammer gesture X,Y',deltaX,deltaY)

    // While card is transitioning, don't respond to events
    if (isTransitioning) {
      return;
    }

    // Don't respond to gestures that are more vertical than horizontal
    // (browser will handle vertical scroll)
    // Unless the gesture started horizontal, then respond as normal
    if (
      (Math.abs(deltaX) < 8 || Math.abs(deltaY) - Math.abs(deltaX) > -1) &&
      !translateX &&
      !leftEdgeScale &&
      !rightEdgeScale
    ) {
      return;
    }

    
    if (
      (!isPreviousAvailable && deltaX > 0) ||
      (!isNextAvailable && deltaX < 0)
    ) {
      this.updateEdgeEffect(deltaX, isFinal);
    } else if (isFinal) {
      this.handleGestureEnd(deltaX);
    } else {
      this.handleGestureMove(deltaX);
    }
  }

  handleGestureMove(deltaX) {
    const { maxTranslateX } = this;

    // Record farthest distance in one direction so can check if gesture goes in
    // opposite direction, indicating user doesn't want to change slides
    if (Math.abs(deltaX) > Math.abs(maxTranslateX)) {
      this.maxTranslateX = deltaX;
    }

    // Move items by deltaX amount
    this.translateX = deltaX;
    this.transitionClass = "transition-initial";
    this.transformStyle = `translateX(${deltaX}px)`;
  }

  handleGestureEnd(deltaX?: number) {
    const { translateX, maxTranslateX } = this;

    if (Math.abs(translateX) - Math.abs(maxTranslateX) < -1) {
      // If gesture goes too much in oposite direction, stay on current slide
      this.transitionClass = 'transition-item';
      this.transformStyle = 'translateX(0)';
    } else if (translateX > 0) {
      this.previous();
    } else if (translateX < 0) {
      this.next();
    }
  }

  updateEdgeEffect(deltaX = 0, isFinal = false) {
    if (isFinal) {
      this.transitionClass = "transition-edge";
      this.leftEdgeScale = 0;
      this.rightEdgeScale = 0;
    } else {
      this.transitionClass = "transition-initial";
      const scaleVal = Math.min(0.2 + Math.abs(deltaX) / 50, 1);
      if (deltaX > 0) {
        this.leftEdgeScale = scaleVal;
      }
      if (deltaX < 0) {
        this.rightEdgeScale = scaleVal;
      }
    }
  }

  // Debounce previous & next functions so only triggered by individual gestures
  // FIXME missing debounce
  previous() {
    if (this.isTransitioning) {
      return;
    }

    if (!this.isPreviousAvailable) {
      this.updateEdgeEffect(100, false);
      setTimeout(() => {
        this.updateEdgeEffect(0, true);
      }, 100);
      return;
    }

    const { currentIndex, lessons, prefersReducedMotion } = this;

    this.transitionClass = "transition-item";
    this.transformStyle = "translateX(100vw)";

    const prevIndex =
      currentIndex === 0 ? lessons.length - 1 : currentIndex - 1;
    this.upcomingIndex = prevIndex;

    if (prefersReducedMotion) {
      this.updateCurrentItem();
    }
  }

  // Respond to "next" navigation request
  // Figure out which card is next and call updateCurrentItem
  // FIXME missing debounce
  next (){
    if (this.isTransitioning) {
      return;
    }

    if (!this.isNextAvailable) {
      this.updateEdgeEffect(-100, false);
      setTimeout(() => {
        this.updateEdgeEffect(0, true);
      }, 100);
      return;
    }

    const { currentIndex, lessons, prefersReducedMotion } = this;

    this.transitionClass = "transition-item";
    this.transformStyle = "translateX(-100vw)";

    const nextIndex =
      currentIndex === lessons.length - 1 ? 0 : currentIndex + 1;
    this.upcomingIndex = nextIndex;

    if (prefersReducedMotion) {
      this.updateCurrentItem();
    }
  }

  // If using Vue Router or Vuex, can put that logic here instead of just changing local state
  updateCurrentItem() {
    this.currentIndex = this.upcomingIndex;
    this.$emit('changeCard', this.renderedItems);
    // console.log('---DBG: changeCard',this.renderedItems)
    this.resetTranslate();
  }

  resetTranslate() {
    this.isTransitioning = false;
    this.transitionClass = "transition-initial";
    this.transformStyle = "translateX(0)";
    this.translateX = 0;
    this.maxTranslateX = 0;
  }
}
