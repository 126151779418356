



























































































import { Component, Prop, Vue } from 'vue-property-decorator';
import MILIDIcons from './MILIDIcons.vue';

@Component({
    components: { MILIDIcons },
})
export default class DefinitionPopup extends Vue {
    @Prop() open!: boolean;
    @Prop() dataId!: string;
    @Prop() theme!: number;

    get iconColor(){
        return "#ffffff";
    }

    close(){
        this.$emit('closerequest', null);
    }
}
