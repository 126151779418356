







import { Component, Prop, Vue } from 'vue-property-decorator';
import ModuleProgress from '../components/ModuleProgress.vue';
import { $metric } from '@/services';

import { MILID } from '../models';

@Component({
  components:{ ModuleProgress }
})
export default class ModuleStatus extends Vue {
  private _subs: any = null;
  private pips = 0;
  @Prop() private module!: MILID.Module;

  mounted() {
    // FIXME remove sub on exit // unsubscribe() 
    this._subs = $metric.onUpdate().subscribe(this.computePips);
    this.computePips();
  }

  beforeDestroy() {
    if(this._subs) {
      this._subs.unsubscribe();
    }
  }

  computePips(event?) {
    const lessons = Object.keys($metric.progressionState);
    this.pips = lessons.filter(lesson => {
      return $metric.progressionState[lesson].state == 'done' && 
             $metric.progressionState[lesson].module == this.module.id;
    }).length || 0;    
  }
}
