



























































































































































/* eslint-disable */
import { Component, Vue, Prop } from 'vue-property-decorator';
import MILIDIcons from '../components/MILIDIcons.vue';
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
import CompletionButton from './CompletionButton.vue'

import { $config, $module, $metric } from '@/services';
import { MILID } from '../models';
import { formatTime } from '../helpers/milidHelpers';

@Component({
  components: {
    MILIDIcons,
    LottieAnimation,
    CompletionButton,
  },
})
export default class LessonVideo extends Vue {
  @Prop() readonly moduleId!:string;
  @Prop() readonly lessonId!:string;

  duration = 0;
  elapsed = "0";
  isPlaying = false;
  
  lottieController:any = null;
  lottieOptions:any = null;

  isReadyToPlay = false;
  isLoading = true;

  get lesson() {
    return $module.getLessonForModuleAndLessonId(this.moduleId,this.lessonId);
  }

  get module() {
    return $module.getModuleWithId(this.moduleId);    
  }

  get theme(){
      return $module.getModuleWithId(this.moduleId).theme;
  }
  
  get lottiePath(){
    return `lottie/video-theme-${this.moduleId}.json`;
  }

  get cssVars() {
    const theme = $config.store.config.themes[this.theme];

    return {
      '--primary': theme.primary,
      '--secondary': theme.secondary,
      '--tertiary': theme.tertiary
    }
  }  

  get mediaUrl() {
    // return "http://media.w3.org/2010/05/sintel/trailer.mp4";
    return this.lesson.media;
  }

  get completed(){
    return this.state == "done";
  }

  get state() {
    const metric = $metric.progressionState[this.lesson.id] || {};
    return metric.state || '';
  }

  get elapsedStr(){
      return formatTime(this.elapsed);
  }

  get durationStr(){
      return formatTime(this.duration);
  }

  get video(){
      return this.$refs.video as HTMLVideoElement;
  }

  mounted(){
      this.video.addEventListener('ended', this.onTrackEnded);
      this.video.addEventListener('timeupdate', this.onTimeUpdate);
      this.video.addEventListener('loadedmetadata', this.onMetaLoaded);
      this.video.addEventListener('waiting', this.onWait);
      this.video.addEventListener('canplay', this.onCanPlay);
  }

  beforeDestroy(){
      this.video.removeEventListener('ended', this.onTrackEnded);
      this.video.removeEventListener('timeupdate', this.onTimeUpdate);
      this.video.removeEventListener('loadedmetadata', this.onMetaLoaded);
      this.video.removeEventListener('waiting', this.onWait);
      this.video.removeEventListener('canplay', this.onCanPlay);
  }

  onMetaLoaded(){
    this.duration = Math.floor(this.video.duration);
    this.elapsed = "0";

    // ISSUE on iOS
    // https://stackoverflow.com/questions/50051639/javascript-html5-video-event-canplay-not-firing-on-safari
    this.isLoading = false;
  }

  onTrackEnded(){
      this.isPlaying = false;
  }

  onTimeUpdate(){
      this.elapsed = `${Math.floor(this.video.currentTime)}`;
  }

  onToggle() {
    if(this.isPlaying) {
      this.onPause();
    } else {
      this.onPlay();
    }
  }

  // pause request
  onPause(){
    if(!this.isPlaying) return;

    this.video.pause();
    this.isPlaying = false;
    
    if(this.lottieController){
        this.lottieController.pause();
    }
  }

  // play request
  onPlay() {
    if(this.isPlaying) return;

    this.video.play();
    this.isPlaying = true;
    this.isReadyToPlay = true;

    if(this.lottieController){
        this.lottieController.play();
    }
  }

  onSeekForwards(){
    this.video.currentTime = Math.min(this.video.currentTime + 10, this.video.duration);
  }

  onSeekBackwards(){
    this.video.currentTime = Math.max(this.video.currentTime - 10, 0);
  }

  onloaded() {

  }

  onWait() {
    this.isReadyToPlay = false
    this.isLoading = true
    console.log('--DBG onWait for data')
  }

  onCanPlay() {
    this.isLoading = false
  }
  
  setAnimController(controller){
    this.lottieController = controller;
  }

  onCompletionHandler(){
    const params = {
        lesson: this.lessonId,
        module: this.moduleId,
        state: MILID.LessonState.DONE
    };
    $metric.event(params);
  }

}
