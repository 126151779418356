




















































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { $config, $user, $metric } from '../services';

import MILIDIcons from '../components/MILIDIcons.vue';
import ParametersPage from '../components/ParametersPage.vue';
import  cfg from '../../package.json';
import { MILID } from '../models';

@Component({
  components: {
    MILIDIcons,
    ParametersPage,
  },
})
export default class Landing extends Vue {
  open = false;
  deferredPrompt: any = {};
  pseudo = '';
  version = cfg.version;

  showParameters = false;

  isInstallAvailable = true;

  async mounted(){

    this.isInstallAvailable = ($config.isAndroid()|| ($config.isSafari() && $config.isIos()));
    window.addEventListener('beforeinstallprompt', (e) => {
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      e.preventDefault();

      // Stash the event so it can be triggered later.
      this.deferredPrompt = e;
    });

    this.pseudo = this.user.name || '';

    const params = {
        lesson: 'landing',
        module: 'landing',
        state: MILID.LessonState.DONE
    };
    $metric.event(params);
  }

  get config(){
    return $config.store.config;
  }

  get user() {
    return $user.user;
  }

  get installed() {
    return $config.isInStandaloneMode();
  }

  beforeRouteEnter(to: any, from: any, next: any) {
    const all = [$config.get(),$user.get()]
    Promise.all(all).then(([config, user])=> {
      if(user && user.id && user.name && $config.isInStandaloneMode()) {
        return next('/module');
      }
      next();
    })
  }

  i18n(key) {
    return $config.i18n(key);
  }


  onEnter(){
    if(!this.pseudo || this.pseudo.length < 3){
      return;
    }
    $user.createUser(this.pseudo);
    this.$router.push({path:'/module' });
  }

  onOpen(){
    if($user.user.name) {
      return this.$router.push({path:'/module' });
    }
    this.open = true;
  }

  
  onInstall($event) {
    const deferredPrompt = this.deferredPrompt;
    // Show the prompt
    if($config.isIos()) {
      return window.dispatchEvent(new Event('installprompt'));
    }

    if(!deferredPrompt.prompt) {
      console.log('---DBG alternative install message',$event);
      $event.preventDefault();
      $event.stopPropagation();
      return;
    }

    deferredPrompt.prompt();

    // Wait for the user to respond to the prompt
    deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the A2HS prompt', choiceResult);
      } else {
        console.log('User dismissed the A2HS prompt', choiceResult);
      }
      this.deferredPrompt = {};
    }); 
    
  }

}
