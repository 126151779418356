













import { Component, Prop, Vue } from 'vue-property-decorator';

import MILIDIcons from '../components/MILIDIcons.vue';

import { $module } from '@/services/module-service';
import { $config } from '@/services/config-service';

import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

@Component({
  components: {
    MILIDIcons    
  },
})
export default class LessonSources extends Vue {
  @Prop() readonly moduleId!: string;
  @Prop() readonly lessonId!: string;

  open = false;

  get module() {
    return $module.getModuleWithId(this.moduleId);    
  }

  get lesson(){
    const lesson = $module.getLessonForModuleAndLessonId(this.moduleId, this.lessonId);
    // console.log('--DBG',lesson.sources);
    return lesson;
  }

  get theme(){
      return $module.getModuleWithId(this.moduleId).theme;
  }


  get colorPrimary() {
    return $config.store.config.themes[this.theme].primary;
  }

  get colorSecondary() {
    return $config.store.config.themes[this.theme].secondary;
  }

  get colorTertiary() {
    return $config.store.config.themes[this.theme].tertiary;
  }


  onToggle(){
    this.open = !this.open;
    if(this.open){
      setTimeout(() => {

        const container = this.$refs.container as any;
        disableBodyScroll(container);
      }, 200);
    }

  }

}
